import React from 'react';
import logo_new from '../images/logo_new.png';
import './NotFound.css'

const NotFound = () => {
  return (
    <section>
      <a href="/">
        <img src={logo_new} alt="Логотип ПЕРСИКОММ" className="logoNew" />
      </a>
      <div className="page">
        <h1 className="pageTitle">Страница не найдена!</h1>
      </div>
    </section>
  );
};

export default NotFound;
