import styled from 'styled-components';

export const StyledButton = styled.a`
  border: 1px solid #1480de;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;

  color: ${({ btn }) => btn?.color || '#000000'};
  background-color: ${({ btn }) => btn?.backgroundColor || '#ffffff'};
  border-color: ${({ btn }) => btn?.borderColor || '#1480de'};
  font-weight: ${({ btn }) => btn?.fontWeight || 400};

  &:hover {
    color: ${({ btn }) => btn?.hover?.color || '#000000'};
    background-color: ${({ btn }) => btn?.hover?.backgroundColor || '#ffffff'};
    border-color: ${({ btn }) => btn?.hover?.borderColor || '#1480de'};
  }

  &:active {
    color: ${({ btn }) => btn?.active?.color || '#000000'};
    background-color: ${({ btn }) => btn?.active?.backgroundColor || '#ffffff'};
    border-color: ${({ btn }) => btn?.active?.borderColor || '#1480de'};
  }
`;


