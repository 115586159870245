import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import axios from 'axios';
import Deflogo from './logo.png';
import poster from './poster.png';
import './Player.css';
import { StyledButton } from './StyledButton';
import { postMetrics, postVideoStats, postClicksStats } from '../api/api';

const Player = () => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');
  const [targetButtons, setTargetButtons] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [logo, setLogo] = useState(Deflogo);
  const [styles, setStyles] = useState(null);
  const [posterUrl, setPosterUrl] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [metrics, setMetrics] = useState({
    date: '',
    time: '',
    video_url: '',
    pers_parameters: []
  });
  const [encrypptId, setEncryptId] = useState(null);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const date = new Date();
        // Определяем московский часовой пояс (GMT+3)
        const optionsDate = {
          timeZone: 'Europe/Moscow',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        };

        const optionsTime = {
          timeZone: 'Europe/Moscow',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        };

        // Преобразуем дату и время в строку
        const moscowDate = new Intl.DateTimeFormat('en-CA', optionsDate).format(date);
        const moscowTime = new Intl.DateTimeFormat('ru-RU', optionsTime).format(date);

        // Преобразуем дату к формату гггг-мм-дд
        const formattedDate = moscowDate.replace(/\./g, '-');
        // Преобразуем время к формату чч:мм
        const formattedTime = moscowTime.replace(',', '');

        setShowPopup(true);
        const shortLink = window.location.search.substring(1);

        if (shortLink) {
          const params = { short_link: shortLink };

          const response = await axios.post(
            `https://api.persicom.ru/projects/generate_custom_video`,
            params
          );

          setVideoUrl(response.data.video_url);
          setTargetButtons(response.data.target_buttons);
          setProjectName(response.data.project_name);
          setTitle(response.data.title);
          setDescription(response.data.description);
          setStyles(response.data.styles);
          setPosterUrl(response.data.poster_url);
          document.title = response.data.project_name || 'Default Project Name';
          setLogo(response.data.logo_url || Deflogo);
          setEncryptId(response.data.encrypt_id);
          setMetrics({
            ...metrics,
            video_url: window.location.href,
            pers_parameters: response.data.pers_blocks,
            date: formattedDate,
            time: formattedTime
          });

          await postMetrics(response.data.encrypt_id, {
            date: formattedDate,
            time: formattedTime,
            pers_parameters: response.data.pers_blocks,
            video_url: window.location.href
          });
        } else {
          console.error('Short link not found in the URL');
        }

        setShowPopup(false);
      } catch (error) {
        console.error('Error fetching project details:', error);
        setShowPopup(false);
      }
    };

    fetchProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (styles && styles?.background) {
      if (styles.background?.backgroundColor) {
        document.body.style.background = styles.background.backgroundColor;
      } else if (styles.background?.backgroundImage) {
        document.body.style.background = styles.background.backgroundImage;
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
      }
    }

    return () => {
      document.body.style.background = '';
      document.body.style.backgroundAttachment = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  }, [styles]);

  const handleTimeUpdate = () => {
    setCurrentTime(Math.floor(videoRef.current.currentTime));
  };

  // Получаем длину видео, когда оно загружено
  const handleLoadedMetadata = () => {
    setDuration(Math.ceil(videoRef.current.duration));
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickPause = async () => {
    await postVideoStats(currentTime, window.location.href, duration);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    // Обработчик события паузы
    const handlePause = () => {
      setIsPaused(true);
      handleClickPause();
    };

    // Обработчик события воспроизведения
    const handlePlay = () => {
      setIsPaused(false);
    };

    // Подписка на события
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('play', handlePlay);

    // Очистка подписки при размонтировании компонента
    return () => {
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('play', handlePlay);
    };
  }, [handleClickPause]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendClick = async button => {
    await postClicksStats(encrypptId, {
      date: metrics.date,
      time: metrics.time,
      video_url: metrics.video_url,
      pers_parameters: metrics.pers_parameters,
      clicks: [button]
    });
  };

  const memoizedTargetButtons = useMemo(() => {
    return targetButtons.map((button, index) => {
      const isProtocolPresent = /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(button.link);
      const link = isProtocolPresent ? button.link : `https://${button.link}`;

      return (
        <StyledButton
          onClick={() => {
            sendClick(button.name);
          }}
          key={index}
          btn={styles?.button}
          href={link}
          rel="noreferrer"
          target="_blank"
        >
          {button.name}
        </StyledButton>
      );
    });
  }, [targetButtons, styles?.button, sendClick]);

  return (
    <div className="player-wrapper">
      <header className="header">
        <img className="logo" src={logo} alt="Логотип ПЕРСИКОММ" />
        <div className="title-wrapper">
          <h1 className="header-title">{title}</h1>
          <p>{description}</p>
        </div>
      </header>
      <div className="player-content">
        <video
          ref={videoRef}
          src={videoUrl}
          className="video-player"
          controls
          poster={posterUrl || poster}
        ></video>
      </div>
      <div className="button-group">{memoizedTargetButtons}</div>
      {showPopup && (
        <div className="popup">
          <div className="popup_inner">
            <p>Подождите минутку, записываем для вас приветственное видео!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
